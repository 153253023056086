:root {
  --body-base-font-family: "Inter", Helvetica;
  --body-base-font-size: 16px;
  --body-base-font-style: normal;
  --body-base-font-weight: 400;
  --body-base-letter-spacing: 0px;
  --body-base-line-height: 140.0%;
  --body-link-font-family: "Inter", Helvetica;
  --body-link-font-size: 16px;
  --body-link-font-style: normal;
  --body-link-font-weight: 400;
  --body-link-letter-spacing: 0px;
  --body-link-line-height: 140.0%;
  --color-background-brand-default: var(--color-primitives-brand-800);
  --color-background-brand-hover: var(--color-primitives-brand-900);
  --color-background-default-default: var(--color-primitives-white-1000);
  --color-background-disabled-default: var(--color-primitives-brand-300);
  --color-background-neutral-tertiary: var(--color-primitives-slate-200);
  --color-background-neutral-tertiary-hover: var(--color-primitives-slate-300);
  --color-border-brand-default: var(--color-primitives-brand-800);
  --color-border-danger-default: var(--color-primitives-red-700);
  --color-border-default-default: var(--color-primitives-gray-300);
  --color-border-disabled-default: var(--color-primitives-brand-400);
  --color-border-disabled-secondary: var(--color-primitives-slate-400);
  --color-border-neutral-secondary: var(--color-primitives-slate-600);
  --color-primitives-brand-100: #f5f5f5;
  --color-primitives-brand-300: #d9d9d9;
  --color-primitives-brand-400: #b3b3b3;
  --color-primitives-brand-500: #757575;
  --color-primitives-brand-600: #444;
  --color-primitives-brand-700: #383838;
  --color-primitives-brand-800: #2c2c2c;
  --color-primitives-brand-900: #1e1e1e;
  --color-primitives-gray-300: #d9d9d9;
  --color-primitives-gray-400: #b3b3b3;
  --color-primitives-gray-600: #444;
  --color-primitives-gray-900: #1e1e1e;
  --color-primitives-red-200: #fdd3d0;
  --color-primitives-red-700: #900b09;
  --color-primitives-slate-1000: #242424;
  --color-primitives-slate-200: #e3e3e3;
  --color-primitives-slate-300: #cdcdcd;
  --color-primitives-slate-400: #b2b2b2;
  --color-primitives-slate-500: #949494;
  --color-primitives-slate-600: #767676;
  --color-primitives-slate-900: #303030;
  --color-primitives-white-1000: #fff;
  --color-primitives-white-400: #fff6;
  --color-text-brand-on-brand: var(--color-primitives-brand-100);
  --color-text-default-default: var(--color-primitives-gray-900);
  --color-text-default-tertiary: var(--color-primitives-gray-400);
  --color-text-disabled-default: var(--color-primitives-brand-400);
  --color-text-disabled-on-disabled: var(--color-primitives-brand-400);
  --color-text-neutral-default: var(--color-primitives-slate-900);
  --dark: #121316;
  --dark-950: #282a37;
  --dark-secondary: #1c1e22;
  --light: #f5f5f5;
  --light-secondary: #f4edf4;
  --primary: #0d7c18;
  --single-line-body-base-font-family: "Inter", Helvetica;
  --single-line-body-base-font-size: 16px;
  --single-line-body-base-font-style: normal;
  --single-line-body-base-font-weight: 400;
  --single-line-body-base-letter-spacing: 0px;
  --single-line-body-base-line-height: 100%;
  --size-radius-200: 8px;
  --size-space-200: 8px;
  --size-space-300: 12px;
  --size-space-400: 16px;
  --size-space-600: 24px;
  --typography-100: #ecedf2;
  --typography-200: #d5d8e2;
  --typography-300: #b0b5c9;
  --typography-500: #667091;
  --typography-600: #515978;
  --typography-800: #3a3f52;
  --typography-950: #282a37;
}

[data-color-mode="SDS-light"] {
  --color-background-brand-default: var(--color-primitives-brand-800);
  --color-background-brand-hover: var(--color-primitives-brand-900);
  --color-background-default-default: var(--color-primitives-white-1000);
  --color-background-disabled-default: var(--color-primitives-brand-300);
  --color-background-neutral-tertiary: var(--color-primitives-slate-200);
  --color-background-neutral-tertiary-hover: var(--color-primitives-slate-300);
  --color-border-brand-default: var(--color-primitives-brand-800);
  --color-border-danger-default: var(--color-primitives-red-700);
  --color-border-default-default: var(--color-primitives-gray-300);
  --color-border-disabled-default: var(--color-primitives-brand-400);
  --color-border-disabled-secondary: var(--color-primitives-slate-400);
  --color-border-neutral-secondary: var(--color-primitives-slate-600);
  --color-text-brand-on-brand: var(--color-primitives-brand-100);
  --color-text-default-default: var(--color-primitives-gray-900);
  --color-text-default-tertiary: var(--color-primitives-gray-400);
  --color-text-disabled-default: var(--color-primitives-brand-400);
  --color-text-neutral-default: var(--color-primitives-slate-900);
}

[data-color-mode="SDS-dark"] {
  --color-background-brand-default: var(--color-primitives-brand-100);
  --color-background-brand-hover: var(--color-primitives-brand-300);
  --color-background-default-default: var(--color-primitives-gray-900);
  --color-background-disabled-default: var(--color-primitives-brand-700);
  --color-background-neutral-tertiary: var(--color-primitives-slate-900);
  --color-background-neutral-tertiary-hover: var(--color-primitives-slate-1000);
  --color-border-brand-default: var(--color-primitives-brand-100);
  --color-border-danger-default: var(--color-primitives-red-200);
  --color-border-default-default: var(--color-primitives-gray-600);
  --color-border-disabled-default: var(--color-primitives-brand-600);
  --color-border-disabled-secondary: var(--color-primitives-brand-600);
  --color-border-neutral-secondary: var(--color-primitives-slate-500);
  --color-text-brand-on-brand: var(--color-primitives-brand-900);
  --color-text-default-default: var(--color-primitives-white-1000);
  --color-text-default-tertiary: var(--color-primitives-white-400);
  --color-text-disabled-default: var(--color-primitives-brand-500);
  --color-text-neutral-default: var(--color-primitives-slate-200);
}
/*# sourceMappingURL=index.26f45930.css.map */
