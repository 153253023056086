:root {
  --body-base-font-family: "Inter", Helvetica;
  --body-base-font-size: 16px;
  --body-base-font-style: normal;
  --body-base-font-weight: 400;
  --body-base-letter-spacing: 0px;
  --body-base-line-height: 139.9999976158142%;
  --body-link-font-family: "Inter", Helvetica;
  --body-link-font-size: 16px;
  --body-link-font-style: normal;
  --body-link-font-weight: 400;
  --body-link-letter-spacing: 0px;
  --body-link-line-height: 139.9999976158142%;
  --color-background-brand-default: var(--color-primitives-brand-800);
  --color-background-brand-hover: var(--color-primitives-brand-900);
  --color-background-default-default: var(--color-primitives-white-1000);
  --color-background-disabled-default: var(--color-primitives-brand-300);
  --color-background-neutral-tertiary: var(--color-primitives-slate-200);
  --color-background-neutral-tertiary-hover: var(--color-primitives-slate-300);
  --color-border-brand-default: var(--color-primitives-brand-800);
  --color-border-danger-default: var(--color-primitives-red-700);
  --color-border-default-default: var(--color-primitives-gray-300);
  --color-border-disabled-default: var(--color-primitives-brand-400);
  --color-border-disabled-secondary: var(--color-primitives-slate-400);
  --color-border-neutral-secondary: var(--color-primitives-slate-600);
  --color-primitives-brand-100: rgba(245, 245, 245, 1);
  --color-primitives-brand-300: rgba(217, 217, 217, 1);
  --color-primitives-brand-400: rgba(179, 179, 179, 1);
  --color-primitives-brand-500: rgba(117, 117, 117, 1);
  --color-primitives-brand-600: rgba(68, 68, 68, 1);
  --color-primitives-brand-700: rgba(56, 56, 56, 1);
  --color-primitives-brand-800: rgba(44, 44, 44, 1);
  --color-primitives-brand-900: rgba(30, 30, 30, 1);
  --color-primitives-gray-300: rgba(217, 217, 217, 1);
  --color-primitives-gray-400: rgba(179, 179, 179, 1);
  --color-primitives-gray-600: rgba(68, 68, 68, 1);
  --color-primitives-gray-900: rgba(30, 30, 30, 1);
  --color-primitives-red-200: rgba(253, 211, 208, 1);
  --color-primitives-red-700: rgba(144, 11, 9, 1);
  --color-primitives-slate-1000: rgba(36, 36, 36, 1);
  --color-primitives-slate-200: rgba(227, 227, 227, 1);
  --color-primitives-slate-300: rgba(205, 205, 205, 1);
  --color-primitives-slate-400: rgba(178, 178, 178, 1);
  --color-primitives-slate-500: rgba(148, 148, 148, 1);
  --color-primitives-slate-600: rgba(118, 118, 118, 1);
  --color-primitives-slate-900: rgba(48, 48, 48, 1);
  --color-primitives-white-1000: rgba(255, 255, 255, 1);
  --color-primitives-white-400: rgba(255, 255, 255, 0.4);
  --color-text-brand-on-brand: var(--color-primitives-brand-100);
  --color-text-default-default: var(--color-primitives-gray-900);
  --color-text-default-tertiary: var(--color-primitives-gray-400);
  --color-text-disabled-default: var(--color-primitives-brand-400);
  --color-text-disabled-on-disabled: var(--color-primitives-brand-400);
  --color-text-neutral-default: var(--color-primitives-slate-900);
  --dark: rgba(18, 19, 22, 1);
  --dark-950: rgba(40, 42, 55, 1);
  --dark-secondary: rgba(28, 30, 34, 1);
  --light: rgba(245, 245, 245, 1);
  --light-secondary: rgba(244, 237, 244, 1);
  --primary: rgba(13, 124, 24, 1);
  --single-line-body-base-font-family: "Inter", Helvetica;
  --single-line-body-base-font-size: 16px;
  --single-line-body-base-font-style: normal;
  --single-line-body-base-font-weight: 400;
  --single-line-body-base-letter-spacing: 0px;
  --single-line-body-base-line-height: 100%;
  --size-radius-200: 8px;
  --size-space-200: 8px;
  --size-space-300: 12px;
  --size-space-400: 16px;
  --size-space-600: 24px;
  --typography-100: rgba(236, 237, 242, 1);
  --typography-200: rgba(213, 216, 226, 1);
  --typography-300: rgba(176, 181, 201, 1);
  --typography-500: rgba(102, 112, 145, 1);
  --typography-600: rgba(81, 89, 120, 1);
  --typography-800: rgba(58, 63, 82, 1);
  --typography-950: rgba(40, 42, 55, 1);
}

/*

To enable a theme in your HTML, simply add one of the following data attributes to an HTML element, like so:

<body data-color-mode="SDS-light">
    <!-- the rest of your content -->
</body>

You can apply the theme on any DOM node, not just the `body`

*/

[data-color-mode="SDS-light"] {
  --color-background-brand-default: var(--color-primitives-brand-800);
  --color-background-brand-hover: var(--color-primitives-brand-900);
  --color-background-default-default: var(--color-primitives-white-1000);
  --color-background-disabled-default: var(--color-primitives-brand-300);
  --color-background-neutral-tertiary: var(--color-primitives-slate-200);
  --color-background-neutral-tertiary-hover: var(--color-primitives-slate-300);
  --color-border-brand-default: var(--color-primitives-brand-800);
  --color-border-danger-default: var(--color-primitives-red-700);
  --color-border-default-default: var(--color-primitives-gray-300);
  --color-border-disabled-default: var(--color-primitives-brand-400);
  --color-border-disabled-secondary: var(--color-primitives-slate-400);
  --color-border-neutral-secondary: var(--color-primitives-slate-600);
  --color-text-brand-on-brand: var(--color-primitives-brand-100);
  --color-text-default-default: var(--color-primitives-gray-900);
  --color-text-default-tertiary: var(--color-primitives-gray-400);
  --color-text-disabled-default: var(--color-primitives-brand-400);
  --color-text-neutral-default: var(--color-primitives-slate-900);
}

[data-color-mode="SDS-dark"] {
  --color-background-brand-default: var(--color-primitives-brand-100);
  --color-background-brand-hover: var(--color-primitives-brand-300);
  --color-background-default-default: var(--color-primitives-gray-900);
  --color-background-disabled-default: var(--color-primitives-brand-700);
  --color-background-neutral-tertiary: var(--color-primitives-slate-900);
  --color-background-neutral-tertiary-hover: var(--color-primitives-slate-1000);
  --color-border-brand-default: var(--color-primitives-brand-100);
  --color-border-danger-default: var(--color-primitives-red-200);
  --color-border-default-default: var(--color-primitives-gray-600);
  --color-border-disabled-default: var(--color-primitives-brand-600);
  --color-border-disabled-secondary: var(--color-primitives-brand-600);
  --color-border-neutral-secondary: var(--color-primitives-slate-500);
  --color-text-brand-on-brand: var(--color-primitives-brand-900);
  --color-text-default-default: var(--color-primitives-white-1000);
  --color-text-default-tertiary: var(--color-primitives-white-400);
  --color-text-disabled-default: var(--color-primitives-brand-500);
  --color-text-neutral-default: var(--color-primitives-slate-200);
}
